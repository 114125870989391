#header {
  position: sticky;
  left: 0;
  right: 0;
  height: 80px;
  background-color: #000;
  display: flex;
  align-items: center;
  padding: 0 10px;
  overflow-x: scroll;
  overflow-y: hidden;
}

#header .header-logo {
  height: 40px;
  margin-right: 30px;
}

#header nav a {
  border: none;
  background: none;
  color: white;
  font-size: 1em;
  cursor: pointer;
  padding: 10px;
  font-family: inherit;
  font-weight: bold;
  text-decoration: none;
}

#header nav a.active {
  background: white;
  color: #000;
  border-radius: 5px;
}

#header nav.main {
  flex-grow: 1;
}

main {
  margin: 0 20px;
}

input, textarea {
  border: 1px solid #000;
  padding: 10px;
}

.form-top {
  border-bottom: 1px solid #ccc;
  display: flex;
}

.entry {
  flex-grow: 3;
  margin-right: 20px;
}

.entry .field {
  margin-bottom: 15px;
}

.entry .field label {
  display: block;
  font-family: monospace;
  margin-bottom: 5px;
}

.entry .field input, .entry .field textarea {
  display: block;
  width: calc(100% - 20px);
}

.codefield {
  flex-grow: 0;
}

.codefield textarea {
  width: calc(100% - 20px);
  font-family: monospace;
}

.slider {
  width: 100%;
  height: 25px;
  border: 1px solid #000;
  border-radius: 999px;
}

.slider .thumb {
  height: 25px;
  line-height: 25px;
  width: 25px;
  text-align: center;
  background-color: #000;
  color: #fff;
  border-radius: 50%;
  cursor: grab;
}

.slider .track {
  top: 0;
  bottom: 0;
  border-radius: 999px;
}

.slider .track.track-0, .slider.range .track.track-1  {
  background-color: #000;
}

.slider.range .track.track-0 {
  background-color: unset;
}

.brule {
  padding-bottom: 5px;
  border-bottom: 1px solid #ccc;
}
